import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueBreadcrumbs from 'vue-2-breadcrumbs';
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);
Vue.use(VueBreadcrumbs);
import VueMeta from 'vue-meta'
Vue.use(VueMeta);
Vue.config.productionTip = false
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate);
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
new Vue({
  router,
  store,
  created() {
    //this.$store.dispatch("getCategories");
    //this.$store.dispatch("getSiteSettings");
  },
  render: (h) => h(App),
}).$mount("#app");
