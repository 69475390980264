// axios
import axios from "axios";
//const baseURL = "https://www.savershall.com/eurasia-backend/api";
//admin api : http://43.205.178.155/suadmin
const baseURL = "https://eurasiasupplies.com/eurasia_backedapi/api";
// const baseURL = "http://eurasia_backedapi.test/api";
export default axios.create({
  baseURL,
  timeout: 100000,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  // You can add your headers here
});
