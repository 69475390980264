const mutations = {
  SET_LOADING(state, value) {
    state.loading = value;
  },
  SET_CART_HIDE(state, value) {
    state.cartActiveStatus = value;
  },
  SET_CART_SHOW(state, value) {
    state.cartActiveStatus = value;
  },
  SET_PAGE(state, value) {
    state.page = value;
  },
  SET_MB_MENU_SHOW(state, value) {
    state.mbmActiveStatus = value;
  },
  SET_TO_BAG(state, value) {
    // value.amount = value.qty * value.unit_price
    value.amount = value.qty * value.unitPrice;
    value.stock = true;
    state.shoppingBag.push(value);
  },
  // UPDATE_CART(state, payload) {
  //     const prodExist = state.shoppingBag.find((cart) => cart.id === payload.id)
  //     // console.log("product list", prodExist)
  //     prodExist.qty++
  //     // console.log('qty 1 : ', prodExist.qty)
  //     if (prodExist.qty <= payload.product_price[0].quantity) {

  //         if ( prodExist.qty >= payload.product_price[0].tier_quantity_three && payload.product_price[0].tier_quantity_three !== 0 ) {
  //             // console.log('==3==  ')
  //             prodExist.amount = prodExist.qty * payload.product_price[0].tier_price_three
  //         }

  //         else if ( prodExist.qty >= payload.product_price[0].tier_quantity_two && payload.product_price[0].tier_quantity_two !== 0 && prodExist.qty < payload.product_price[0].tier_quantity_three ) {
  //             // console.log('==2==  ')
  //             prodExist.amount =  prodExist.qty * payload.product_price[0].tier_price_two
  //         }
  //         else if ( prodExist.qty >= payload.product_price[0].tier_quantity_two && payload.product_price[0].tier_quantity_two !== 0 && payload.product_price[0].tier_price_three === 0 ) {
  //             // console.log('==ELSE 2==  ')
  //             prodExist.amount =  prodExist.qty * payload.product_price[0].tier_price_two
  //         }

  //         else if ( prodExist.qty >= payload.product_price[0].tier_quantity_one  && payload.product_price[0].tier_quantity_one !== 0 && prodExist.qty < payload.product_price[0].tier_quantity_two ) {
  //             // console.log('==1==  ')
  //             prodExist.amount = prodExist.qty * payload.product_price[0].tier_price_one
  //         }
  //         else if ( prodExist.qty >= payload.product_price[0].tier_quantity_one && payload.product_price[0].tier_quantity_one !== 0 && payload.product_price[0].tier_price_two === 0 ) {
  //             // console.log('==ELSE1==  ')
  //             prodExist.amount = prodExist.qty * payload.product_price[0].tier_price_one
  //         }

  //         else if (prodExist.product_price[0].offer_price) {
  //             // console.log('==Offer==  ', prodExist.qty, prodExist.product_price[0])
  //             prodExist.amount = prodExist.qty * prodExist.product_price[0].offer_price
  //         }

  //         else {
  //             // console.log('==Amount== ')
  //             prodExist.amount = prodExist.qty * payload.product_price[0].price
  //         }

  //     } else {
  //         prodExist.stock = false
  //     }
  //     // if (prodExist.qty <= payload.product_price[0].quantity) {
  //     //     if (
  //     //         prodExist.qty >= payload.product_price[0].tier_quantity_three &&
  //     //         payload.product_price[0].tier_quantity_three !== 0
  //     //     ) {
  //     //         // console.log('==3==  ')
  //     //         prodExist.amount =
  //     //             prodExist.qty * payload.product_price[0].tier_price_three
  //     //     } else if (
  //     //         prodExist.qty >= payload.product_price[0].tier_quantity_two &&
  //     //         prodExist.qty < payload.product_price[0].tier_price_three
  //     //     ) {
  //     //         // console.log('==2==  ')
  //     //         prodExist.amount =
  //     //             prodExist.qty * payload.product_price[0].tier_price_two
  //     //     } else if (
  //     //         prodExist.qty >= payload.product_price[0].tier_quantity_one &&
  //     //         prodExist.qty < payload.product_price[0].tier_price_two
  //     //     ) {
  //     //         // console.log('==1==  ')
  //     //         prodExist.amount =
  //     //             prodExist.qty * payload.product_price[0].tier_price_one
  //     //     } else if (prodExist.product_price[0].offer_price) {
  //     //         // console.log('==Offer==  ', prodExist.qty, prodExist.product_price[0])
  //     //         prodExist.amount =
  //     //             prodExist.qty * prodExist.product_price[0].offer_price
  //     //     } else {
  //     //         // console.log('==Amount== ')
  //     //         prodExist.amount = prodExist.qty * payload.product_price[0].price
  //     //     }
  //     // } else {
  //     //     prodExist.stock = false
  //     // }
  //     state.shoppingBag = [...state.shoppingBag]
  // },
  UPDATE_CART(state, payload) {
    // console.log("payload", payload);
    const prodExist = state.shoppingBag.find((cart) => cart.id === payload.id);
    prodExist.qty++;
    // console.log("qty 1 : ", prodExist.qty);

    if (prodExist.qty <= payload.product_price[0].quantity) {
      if (this.getters.countGroupProduct(payload.group_product_id) > 0) {
        // console.log("countGroupProduct find");
        if (
          this.getters.countGroupProduct(payload.group_product_id) >=
            payload.product_price[0].tier_quantity_three &&
          payload.product_price[0].tier_quantity_three !== 0
        ) {
          // console.log("==3==  ");
          prodExist.amount =
            prodExist.qty * payload.product_price[0].tier_price_three;
          this.getters.updateGroupProduct(
            payload.group_product_id,
            payload.product_price[0].tier_price_three
          );
        } else if (
          this.getters.countGroupProduct(payload.group_product_id) >=
            payload.product_price[0].tier_quantity_two &&
          payload.product_price[0].tier_quantity_two !== 0 &&
          this.getters.countGroupProduct(payload.group_product_id) <
            payload.product_price[0].tier_quantity_three
        ) {
          // console.log("==2==  ");
          prodExist.amount =
            prodExist.qty * payload.product_price[0].tier_price_two;
          this.getters.updateGroupProduct(
            payload.group_product_id,
            payload.product_price[0].tier_price_two
          );
        } else if (
          this.getters.countGroupProduct(payload.group_product_id) >=
            payload.product_price[0].tier_quantity_two &&
          payload.product_price[0].tier_quantity_two !== 0 &&
          payload.product_price[0].tier_price_three === 0
        ) {
          // console.log("==ELSE 2==  ");
          prodExist.amount =
            prodExist.qty * payload.product_price[0].tier_price_two;
          this.getters.updateGroupProduct(
            payload.group_product_id,
            payload.product_price[0].tier_price_two
          );
        } else if (
          this.getters.countGroupProduct(payload.group_product_id) >=
            payload.product_price[0].tier_quantity_one &&
          payload.product_price[0].tier_quantity_one !== 0 &&
          this.getters.countGroupProduct(payload.group_product_id) <
            payload.product_price[0].tier_quantity_two
        ) {
          // console.log("==1==  ");
          prodExist.amount =
            prodExist.qty * payload.product_price[0].tier_price_one;
          this.getters.updateGroupProduct(
            payload.group_product_id,
            payload.product_price[0].tier_price_one
          );
        } else if (
          this.getters.countGroupProduct(payload.group_product_id) >=
            payload.product_price[0].tier_quantity_one &&
          payload.product_price[0].tier_quantity_one !== 0 &&
          payload.product_price[0].tier_price_two === 0
        ) {
          // console.log("==ELSE1==  ");
          prodExist.amount =
            prodExist.qty * payload.product_price[0].tier_price_one;
          this.getters.updateGroupProduct(
            payload.group_product_id,
            payload.product_price[0].tier_price_one
          );
        } else if (prodExist.product_price[0].offer_price) {
          // console.log("==Offer==  ", prodExist.qty, prodExist.product_price[0]);
          prodExist.amount =
            prodExist.qty * prodExist.product_price[0].offer_price;
          this.getters.updateGroupProduct(
            payload.group_product_id,
            payload.product_price[0].offer_price
          );
        } else {
          // console.log("==Amount== ");
          prodExist.amount = prodExist.qty * payload.product_price[0].price;
          this.getters.updateGroupProduct(
            payload.group_product_id,
            payload.product_price[0].price
          );
        }
      } else {
        if (
          prodExist.qty >= payload.product_price[0].tier_quantity_three &&
          payload.product_price[0].tier_quantity_three !== 0
        ) {
          // console.log("==3==  ");
          prodExist.amount =
            prodExist.qty * payload.product_price[0].tier_price_three;
        } else if (
          prodExist.qty >= payload.product_price[0].tier_quantity_two &&
          payload.product_price[0].tier_quantity_two !== 0 &&
          prodExist.qty < payload.product_price[0].tier_quantity_three
        ) {
          // console.log("==2==  ");
          prodExist.amount =
            prodExist.qty * payload.product_price[0].tier_price_two;
        } else if (
          prodExist.qty >= payload.product_price[0].tier_quantity_two &&
          payload.product_price[0].tier_quantity_two !== 0 &&
          payload.product_price[0].tier_price_three === 0
        ) {
          // console.log("==ELSE 2==  ");
          prodExist.amount =
            prodExist.qty * payload.product_price[0].tier_price_two;
        } else if (
          prodExist.qty >= payload.product_price[0].tier_quantity_one &&
          payload.product_price[0].tier_quantity_one !== 0 &&
          prodExist.qty < payload.product_price[0].tier_quantity_two
        ) {
          // console.log("==1==  ");
          prodExist.amount =
            prodExist.qty * payload.product_price[0].tier_price_one;
        } else if (
          prodExist.qty >= payload.product_price[0].tier_quantity_one &&
          payload.product_price[0].tier_quantity_one !== 0 &&
          payload.product_price[0].tier_price_two === 0
        ) {
          // console.log("==ELSE1==  ");
          prodExist.amount =
            prodExist.qty * payload.product_price[0].tier_price_one;
        } else if (prodExist.product_price[0].offer_price) {
          // console.log("==Offer==  ", prodExist.qty, prodExist.product_price[0]);
          prodExist.amount =
            prodExist.qty * prodExist.product_price[0].offer_price;
        } else {
          // console.log("==Amount== ");
          prodExist.amount = prodExist.qty * payload.product_price[0].price;
        }
      }
    } else {
      prodExist.stock = false;
    }

    state.shoppingBag = [...state.shoppingBag];
  },
  DEDUCT_CART(state, payload) {
    const prodExist = state.shoppingBag.find((cart) => cart.id === payload.id);
    prodExist.qty--;

    if (prodExist.qty <= payload.product_price[0].quantity) {
      if (this.getters.countGroupProduct(payload.group_product_id) - 1 > 0) {
        if (
          this.getters.countGroupProduct(payload.group_product_id) >=
            payload.product_price[0].tier_quantity_three &&
          payload.product_price[0].tier_quantity_three !== 0
        ) {
          prodExist.amount =
            prodExist.qty * payload.product_price[0].tier_price_three;
          // this.getters.updateGroupProduct(payload.group_product_id, payload.product_price[0].tier_price_three)
          state.shoppingBag.forEach((item) => {
            if (
              item.group_product_id === payload.group_product_id &&
              item.id != prodExist.id
            ) {
              item.unitPrice = item?.product_price[0]?.tier_price_three;
              item.amount = item.qty * item?.product_price[0]?.tier_price_three;
            }
          });

          // console.log("==3==  ");
        } else if (
          this.getters.countGroupProduct(payload.group_product_id) >=
            payload.product_price[0].tier_quantity_two &&
          payload.product_price[0].tier_quantity_two !== 0
        ) {
          prodExist.amount =
            prodExist.qty * payload.product_price[0].tier_price_two;
          // this.getters.updateGroupProduct(
          //   payload.group_product_id,
          //   payload.product_price[0].tier_price_two
          // );

          state.shoppingBag.forEach((item) => {
            if (
              item.group_product_id === payload.group_product_id &&
              item.id != prodExist.id
            ) {
              item.unitPrice = item?.product_price[0]?.tier_price_two;
              item.amount = item.qty * item?.product_price[0]?.tier_price_two;
            }
          });

          // console.log("==2==  ");
        } else if (
          this.getters.countGroupProduct(payload.group_product_id) >=
            payload.product_price[0].tier_quantity_one &&
          payload.product_price[0].tier_quantity_one !== 0
        ) {
          prodExist.amount =
            prodExist.qty * payload.product_price[0].tier_price_one;
          // this.getters.updateGroupProduct(
          //   payload.group_product_id,
          //   payload.product_price[0].tier_price_one
          // );
          state.shoppingBag.forEach((item) => {
            if (
              item.group_product_id === payload.group_product_id &&
              item.id != prodExist.id
            ) {
              item.unitPrice = item?.product_price[0]?.tier_price_one;
              item.amount = item.qty * item?.product_price[0]?.tier_price_one;
            }
          });

          // console.log("==1==  ");
        } else if (prodExist.product_price[0].offer_price) {
          if (prodExist.qty > 0) {
            // console.log("==Amount2.1== ");
            prodExist.amount =
              prodExist.qty * payload.product_price[0].offer_price;
            // this.getters.updateGroupProduct2(payload.group_product_id);
            state.shoppingBag.forEach((item) => {
              if (
                item.group_product_id === payload.group_product_id &&
                item.id != prodExist.id
              ) {
                item.unitPrice = item?.product_price[0]?.offer_price;
                item.amount = item.qty * item?.product_price[0]?.offer_price;
              }
            });
          } else {
            // console.log("==Amount2.2== ");
            state.shoppingBag.forEach((item) => {
              if (
                item.group_product_id === payload.group_product_id &&
                item.qty > 0
              ) {
                // console.log("==Amount2.3== ");

                item.unitPrice = item.product_price[0].offer_price;
                item.amount = item.qty * item.product_price[0].offer_price;
              }
            });
          }
          // console.log("==Offer==  ");
        } else {
          if (prodExist.qty > 0) {
            // console.log("==Amount2.1== ");
            prodExist.amount = prodExist.qty * payload.product_price[0].price;
            // this.getters.updateGroupProduct2(payload.group_product_id);
            state.shoppingBag.forEach((item) => {
              if (
                item.group_product_id === payload.group_product_id &&
                item.id != prodExist.id
              ) {
                item.unitPrice = item?.product_price[0]?.price;
                item.amount = item.qty * item?.product_price[0]?.price;
              }
            });
          } else {
            // console.log("==Amount2.2== ");
            state.shoppingBag.forEach((item) => {
              if (
                item.group_product_id === payload.group_product_id &&
                item.qty > 0
              ) {
                // console.log("==Amount2.3== ");

                item.unitPrice = item.product_price[0].price;
                item.amount = item.qty * item.product_price[0].price;
              }
            });
          }

          // console.log("==Amount2== ");
          /*prodExist.amount = prodExist.qty * payload.unitPrice*/
        }
      } else {
        if (
          prodExist.qty >= payload.product_price[0].tier_quantity_three &&
          payload.product_price[0].tier_quantity_three !== 0
        ) {
          prodExist.amount =
            prodExist.qty * payload.product_price[0].tier_price_three;
          // console.log("==3==  ");
        } else if (
          prodExist.qty >= payload.product_price[0].tier_quantity_two &&
          payload.product_price[0].tier_quantity_two !== 0
        ) {
          prodExist.amount =
            prodExist.qty * payload.product_price[0].tier_price_two;
          // console.log("==2==  ");
        } else if (
          prodExist.qty >= payload.product_price[0].tier_quantity_one &&
          payload.product_price[0].tier_quantity_one !== 0
        ) {
          prodExist.amount =
            prodExist.qty * payload.product_price[0].tier_price_one;
          // console.log("==1==  ");
        } else if (prodExist.product_price[0].offer_price) {
          prodExist.amount =
            prodExist.qty * prodExist.product_price[0].offer_price;
          // console.log("==Offer==  ");
        } else {
          prodExist.amount = prodExist.qty * payload.product_price[0].price;
          // console.log("==Amount== ");
          /*prodExist.amount = prodExist.qty * payload.unitPrice*/
        }
      }
    } else {
      prodExist.stock = false;
    }
    state.shoppingBag = [...state.shoppingBag];
  },
  //   DEDUCT_CART(state, payload) {
  //     const prodExist = state.shoppingBag.find((cart) => cart.id === payload.id);
  //     prodExist.qty--;
  //     if (prodExist.qty <= payload.product_price[0].quantity) {
  //       if (
  //         prodExist.qty >= payload.product_price[0].tier_quantity_three &&
  //         payload.product_price[0].tier_quantity_three !== 0
  //       ) {
  //         prodExist.amount =
  //           prodExist.qty * payload.product_price[0].tier_price_three;
  //         // console.log("==3==  ");
  //       } else if (
  //         prodExist.qty >= payload.product_price[0].tier_quantity_two &&
  //         payload.product_price[0].tier_quantity_two !== 0
  //       ) {
  //         prodExist.amount =
  //           prodExist.qty * payload.product_price[0].tier_price_two;
  //         // console.log("==2==  ");
  //       } else if (
  //         prodExist.qty >= payload.product_price[0].tier_quantity_one &&
  //         payload.product_price[0].tier_quantity_one !== 0
  //       ) {
  //         prodExist.amount =
  //           prodExist.qty * payload.product_price[0].tier_price_one;
  //         // console.log("==1==  ");
  //       } else if (prodExist.product_price[0].offer_price) {
  //         prodExist.amount =
  //           prodExist.qty * prodExist.product_price[0].offer_price;
  //         // console.log("==Offer==  ");
  //       } else {
  //         prodExist.amount = prodExist.qty * payload.product_price[0].price;
  //         // console.log("==Amount== ");
  //         /*prodExist.amount = prodExist.qty * payload.unitPrice*/
  //       }
  //     } else {
  //       prodExist.stock = false;
  //     }
  //     state.shoppingBag = [...state.shoppingBag];
  //   },
  REMOVE_FROM_CART(state, payload) {
    let i = state.shoppingBag.map((item) => item.id).indexOf(payload.id);
    state.shoppingBag.splice(i, 1);
  },
  SET_CART_NULL(state) {
    state.shoppingBag = [];
  },
  SET_SITE_URL(state, value) {
    state.siteUrl = value;
  },
  SET_CATEGORIES(state, value) {
    state.categories = value;
  },
  SET_CATEGORY(state, value) {
    state.category = value;
  },
  SET_CATEGORY_PRODUCTS(state, value) {
    //// console.log("@@@@--Muta", value);
    state.category.products = [...state.category.products, ...value.products];
    state.category.products = state.category.products.filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
    );
  },
  SET_SITE_SETTINGS(state, value) {
    state.siteSettings = value;
  },
  SET_BRAND(state, value) {
    state.brand = value;
  },
  SET_BRANDS(state, value) {
    state.brands = value;
  },
  SET_BRAND_PRODUCTS(state, value) {
    state.brands.products = [...state.brands.products, ...value.brands];
    state.brands.products = state.brands.products.filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
    );
  },
  SET_BRANDS_TEXT_FILTER(state, value) {
    state.brandsByTextFilter = value;
  },
  SET_CATEGORY_TEXT_FILTER(state, value) {
    state.categoryByTextFilter = value;
  },
  SET_PRODUCT_SEARCH_FILTER(state, value) {
    state.productBySearch = value;
  },
  SET_PRODUCT_SEARCH_PAGE(state, value) {
    state.productForSearchPage = value;
  },
  SET_BRANDS_TEXT_FILTER_HOME(state, value) {
    state.brandsByTextFilterHome = value;
  },
  SET_PRODUCT_DETAILS(state, value) {
    state.productDetails = value;
  },
  SET_ZOOM_IMAGE(state, value) {
    state.zoomeImage = value;
  },
  SET_HOME(state, value) {
    state.home = value;
  },
  SET_SALE_NOW(state, value) {
    state.saleNow = value;
  },
  SET_TOP_SELLING(state, value) {
    state.topSelling = value;
  },
  SET_CUSTOMER(state, value) {
    state.customer = value;
  },
  SET_USER(state, value) {
    state.user = value;
  },
  SET_ORDERS(state, value) {
    state.payment = value;
  },
  SET_ME(state, value) {
    state.me = value;
  },
  SET_LOCATIONS(state, value) {
    state.locations = value;
  },
  SET_CUSTOMER_SUMMERY(state, value) {
    state.customerSummery = value;
  },
  SET_CUSTOMER_WISHLIST(state, value) {
    state.customerWishlist = value;
  },
  SET_PRICE_SEARCH_VAL(state, value) {
    state.priceSearchVal = value;
  },
  SET_SHIPPING_CHARGE(state, value) {
    state.shippingCharge = value;
  },
  SET_ORDER_DETAILS(state, value) {
    state.orderDetails = value;
    // console.log("noolll =<>", state.orderDetails);
  },
  SET_CANCEL_ORDER(state, value) {
    state.orderCancel = value;
  },
  SET_LOAD_MORE_PAGE(state, value) {
    state.loadMorePage = value;
  },
  SET_PURCHASE_ITEMS(state, value) {
    state.purchaseItems = value;
  },
  SET_SINGLE_PRODUCT(state, value) {
    // console.log("cc val", value);
    var parsedObj = JSON.parse(JSON.stringify(value));
    // console.log("cc", parsedObj);
    if (value.length === 0) {
      state.singleProduct = [];
      // console.log("tt", state.singleProduct);
    } else {
      state.singleProduct.push(value);
    }
  },
  SET_NEWSLETTER(state, value) {
    state.newsletter = value;
  },
};
export default mutations;
